import React from 'react'
import '../Style/style.css';
const Home = () => {
  return (
    <div className='containPage'>
        <div className='containSection'>
            <h1>
                El Iceberg del eCommerce
            </h1>
            <div className='icebergImg'/>
        </div>
    </div>
  )
}

export default Home